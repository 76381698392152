import { navigate, graphql } from 'gatsby';
import React, { useEffect } from 'react';
import Seo from 'gatsby-plugin-wpgraphql-seo';
import { InlineWidget } from "react-calendly";
import Layout from '~/components/Layout';
import Image from '~/components/atoms/Image';
import CustomSEO from '~/components/seo/CustomSEO';

export default function PricingResult2(props) {
  // console.log('~~~PricingResult2.js');

  const {
    location: { state },
    data: { wpPage },
  } = props;

  const section = wpPage?.pricingResult2?.pricingResult2

  useEffect(() => {
    if (typeof state?.estimate === 'undefined') {
      return navigate('/pricing');
    }
  }, []);

  return state?.estimate ? (
    <Layout>
      <Seo post={wpPage} />
      <CustomSEO seo={wpPage?.seo} />
      <section className="my-20 md:my-36">
        <div className="container">
          {section.heading && <h1 className="text-h1 text-center mb-12">{section.heading}</h1>}

          <div className="grid grid-cols-1 md:grid-cols-12 gap-8 max-w-5xl mx-auto w-full">
            <div className="col-span-1 md:col-span-5 flex flex-col justify-center py-16 px-6 bg-sand-light text-black">
              {section.assumptions && (
                <div className="max-w-xs mx-auto">
                  {section.assumptions.heading && <h4 className="mb-6 text-left font-normal text-xl text-navy font-heading" dangerouslySetInnerHTML={{ __html: section.assumptions.heading, }} />}
                  <div className="flex flex-col justify-center">
                    <div className="text-sm space-y-3 max-w-[16rem]  mx-auto">
                      {section.assumptions.assumptions?.map((item, i) => (
                        <div key={`assumption${i}`} className="grid grid-cols-12 gap-4 items-center">
                          <div className="col-span-3">
                            <div className="aspect-w-1 aspect-h-1">
                              <div>{item.illustration && <Image data={item.illustration} />}</div>
                            </div>
                          </div>
                          <div className="col-span-9">{item.assumption}</div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              )}
              {section.assumptions2 && (
                <div className="max-w-xs mx-auto">
                  {section.assumptions2.heading && <h4 className="mb-6 text-left font-normal text-xl text-navy font-heading" dangerouslySetInnerHTML={{ __html: section.assumptions2.heading, }} />}
                  <div className="flex flex-col justify-center">
                    <div className="text-sm space-y-3 max-w-[16rem]  mx-auto">
                      {section.assumptions2.assumptions?.map((item, i) => (
                        <div key={`assumption${i}`} className="grid grid-cols-12 gap-4 items-center">
                          <div className="col-span-3">
                            <div className="aspect-w-1 aspect-h-1">
                              <div>{item.illustration && <Image data={item.illustration} />}</div>
                            </div>
                          </div>
                          <div className="col-span-9">{item.assumption}</div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="col-span-1 md:col-span-7 bg-white p-10 border border-opacity-5 border-black flex flex-col">
              {section.calendlyHeading && <h3 className="text-h4 mb-8">{section.calendlyHeading}</h3>}
              <div className="bg-seafoam flex-auto p-5 flex items-center justify-center">
                <InlineWidget
                  styles={{
                    width: '100%',
                    height: '450px'
                  }}
                  pageSettings={{
                    hideEventTypeDetails: true,
                    hideLandingPageDetails: true,
                  }}
                  url="https://meetings.hubspot.com/akim-courail?embed=true%22"
                  prefill={{
                    email: state?.email || '',
                    name: state?.full_name || '',
                  }}
                />
              </div>

            </div>
          </div>

        </div>
      </section>
    </Layout>
  ) : null
}

export const pageQuery = graphql`
  query pricingResult2Query($id: String!) {
    wpPage(id: { eq: $id }) {
      title
      id
      ...SEO
      pricingResult2 {
        pricingResult2 {
          assumptions {
            heading
            assumptions {
              illustration {
                ... Image
              }
              assumption
            }
          }
          assumptions2 {
            heading
            assumptions {
              illustration {
                ... Image
              }
              assumption
            }
          }
          disclaimer
          heading
          calendlyHeading
        }
      }
    }
  }
`
